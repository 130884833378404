import * as React from "react"
import { Carousel } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"

import yoota from "../images/banner/yoota.png"
import plywood from "../images/banner/plywood.png"

const Header = () => {
  return (
    <section className="home" id="home">
      <div className="home__container">
        <Carousel className="carousel" indicators={false}>
          <Carousel.Item>
            <img src={yoota} alt="Yoota - Turbine Ventilator" />
          </Carousel.Item>
          <Carousel.Item>
            <img src={plywood} alt="Plywood" />
          </Carousel.Item>
        </Carousel>
      </div>
    </section>
  )
}

export default Header
